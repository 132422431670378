import '../../styles/pages/fall-winter-2020/anti-racist-teaching.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2020-stories/more-stories"
import StoryNav from "../../components/fall-winter-2020-stories/story-nav"

import socialBanner from "../../images/fall-winter-2020/social-banners/anti-racist-teaching.jpg";

import TitleGraphic from "../../images/fall-winter-2020/anti-racist-teaching/title-graphic.svg";

import antoinetteLinton from "../../images/fall-winter-2020/anti-racist-teaching/antoinette-linton.jpg";
import lisaKirtman from "../../images/fall-winter-2020/anti-racist-teaching/lisa-kirtman.jpg";
import lauraKeisler from "../../images/fall-winter-2020/anti-racist-teaching/laura-keisler.jpg";
import vitaJones from "../../images/fall-winter-2020/anti-racist-teaching/vita-jones.jpg";


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Anti-Racist Teaching';
    var pageDesc = 'College of Education faculty members share stories of addressing racism and injustice in the classroom.';

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2020">
        <Helmet>
          <title>{pageTitle}</title>
          <body className="anti-racist-teaching" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <section className="feature-hero animated fadeIn slow">
          <div className="hero-text">
            <h1 className="first-word animated fadeInDown delay-1s"><img src={TitleGraphic} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInUp delay-2s">College of Education faculty share stories of dismantling racism and injustice in the classroom.</p>
            <p className="author animated fadeInUp delay-2s">By Debra Cano Ramos</p>
          </div>
        </section>
        <article className="wrap small">

          <ScrollAnimation animateIn="fadeIn">
          <p><span className="intro-text">Amid racial and social unrest</span> across the nation, Cal State Fullerton’s College of Education is taking action against racist policies, practices and ideas that influence schools, teachers and children.</p>

          <p>“The goal is to teach educators how to dismantle systems of racism and injustice, to call it out when they see it — and to work to change it,” says Lisa Kirtman, dean of the College of Education. “As educators, we need to think about and discuss how race and privilege play into a particular story and in our schools, and take action to do something about it.”</p>

          <p>Education faculty members share stories on how they have approached a classroom situation and turned it into an anti-racist teaching practice:</p>
          </ScrollAnimation>

          <br />

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-left align-outside circle">
            <img src={lisaKirtman} alt="Lisa Kirtman" />
          </figure>
          <p><span className="intro-text">“When I taught</span> sixth grade, I asked students to draw a picture of a scientist. The majority of students year in and year out would draw a white male. As the teacher, I engaged students in a discussion to ask ‘why.’ Are white men just smarter? No. It’s because over the years, society has made positive assumptions about white males and provided them with opportunities that society does not provide to people of color. In addition, history has tried to remove indications of the contributions of people of color. As a teacher, I would show my students examples of women scientists and scientists of other races, such as African Americans Alice Ball, a chemist who was the first to develop the treatment for leprosy, and Percy Julian, whose work led to drugs to treat arthritis and glaucoma; or Latinx scientists like Ellen Ochoa, the first Latina woman to go to space, and Carlos Juan Finlay, a physician who first linked yellow fever to mosquitoes.”</p>
          <p>— <strong><em>Lisa Kirtman</em></strong>, dean of the College of Education</p>
          </ScrollAnimation>

          <br /><hr /><br />

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-right align-outside circle">
            <img src={antoinetteLinton} alt="Antoinette Linton" />
          </figure>
          <p><span className="intro-text">“As a high school</span> biology and chemistry teacher, I developed my own ways to empower students of color, as well as deal with those who were having challenges. One African American student, for example, struggled with a scientific writing assignment. My response to this student was to work with him one-on-one. I used a conversational style of discourse and did not talk to him like he was incompetent or incapable. I posed questions to help him participate in discussion and understand the process of asking questions, developing hypotheses and telling what he learned. Taking a sociocultural stance to learning biology requires that teachers learn a great deal about their students and the communities in which their students reside to provide for, and support, authentic learning opportunities.”</p>
          <p>— <strong><em>Antoinette Linton</em></strong>, associate professor of secondary education</p>
          </ScrollAnimation>

          <br /><hr /><br />

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-left align-outside circle">
            <img src={vitaJones} alt="Vita Jones" />
          </figure>
          <p><span className="intro-text">“College students report</span> instances where professors use inappropriate terminology whe referring to ethnicities, such as calling students Orientals or Negroes I instruct them to model the appropriate terms in their writing, during class discussions, and to use the American Psychological Association manual for politically correct terms in referring to diverse populations. I further encourage students to have a space where they feel affirmed and appreciated, such as a campus affinity group — as an act of resistance against racism. Encouraging such an inclusive environment provides the opportunity for authentic conversations about the discomfort caused by microaggressions.”</p>
          <p>— <strong><em>Vita Jones</em></strong>, associate professor of special education</p>
          </ScrollAnimation>

          <br /><hr /><br />

          <ScrollAnimation animateIn="fadeIn">
          <figure className="align-right align-outside circle">
            <img src={lauraKeisler} alt="Laura Keisler" />
          </figure>
          <p><span className="intro-text">“A fourth grade teacher</span> I frequently work with had a student ask why they didn’t have Columbus Day off. She truthfully answered that the school district didn’t consider it a holiday. The next day she gave her students an assignment titled ‘Should We Celebrate Columbus Day?’ Over the course of a few days, the students watched and discussed videos of Viking explorers in the Americas, Columbus’ encounters with the Indigenous peoples of Hispaniola and the observance of Dia de la Raza. Many students revised their own understanding of Christopher Columbus and answered ‘no’ to the question of celebrating Columbus Day. This is an example of a teacher engaging in anti-racist work by exposing students to voices that often are excluded from the teaching of this subject and pushing back on the accepted narrative. This lesson scaffolded the development of students’ critical thinking skills by guiding them through questions they might ask, showing them how to find information, and demonstrating how they might revise their own understandings and beliefs when confronted with new information.”</p>
          <p>— <strong><em>Laura Keisler</em></strong>, assistant professor of literacy and reading</p>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn">
          <p className="learn-more">Support the College of Education<br /><a href="https://campaign.fullerton.edu/education" target="_blank">campaign.fullerton.edu/education</a></p>
          </ScrollAnimation>

        </article>
        <MoreStories slug="anti-racist-teaching" />
        <StoryNav slug="anti-racist-teaching" />
      </Layout>
    );
  }
} 